<script lang="ts" setup>
import Parametric from "@/components/three/Parametric.vue";

const {typeState, tweenPositionDelta} = useSceneState();
const zPosition = computed(() => {
  return 20 * (1 - typeState.value.digitalDna);
});

const groupPosition = computed<[number, number, number]>(() => {
  const {absolute} = tweenPositionDelta.value;
  return [absolute * -10 + 5, absolute * -10 + 5, zPosition.value];
});
</script>

<template>
  <Suspense>
    <TresGroup v-if="typeState.digitalDna" :position="groupPosition">
      <Parametric :scale="[2, 2, 1]" :position="[-1, -2, 5]"></Parametric>
    </TresGroup>
  </Suspense>
</template>
