<script lang="ts" setup>
import BadgeNotification from "~/components/BadgeNotification.vue";
import BackgroundRendering from "~/components/three/BackgroundRendering.vue";

const requestUrl = useServerRequestUrl();
const fromInternal = requestUrl.value.host.includes(".platform.trimm.net");
</script>

<template>
  <BackgroundRendering></BackgroundRendering>
  <div>
    <div>
      <Navigation></Navigation>
    </div>
    <div class="content text-white">
      <NuxtErrorBoundary>
        <template #error="{error}">
          <ErrorDisplay :error />
        </template>
        <slot />
      </NuxtErrorBoundary>
    </div>
    <BadgeNotification v-if="fromInternal" />
  </div>
</template>
