<script setup lang="ts">
import type {Page} from "#magnolia-layer/types/magnolia-types";
import {joinURL} from "ufo";

const alertDialogOpen: Ref<boolean> = ref(false);

const toggleAlertDialog = () => {
  alertDialogOpen.value = !alertDialogOpen.value;
};

const magnoliaContext = useMagnoliaContext();
const runtimeConfig = useRuntimeConfig();
const siteRootNode = magnoliaContext.nodePath?.split("/").filter((v) => v)[0];
const url = joinURL(runtimeConfig.public.mgnl.apiPages, siteRootNode ?? "");
const {data} = await useMagnoliaFetch<Page & {showBugReportBadge?: boolean}>(
  url,
);

const show = data.value?.showBugReportBadge
  ? data.value.showBugReportBadge
  : false;
</script>

<template>
  <teleport to="body" v-if="show">
    <div
      class="fixed bottom-3 right-4 left-4 flex flex-row-reverse gap-4 items-end z-50"
      :class="alertDialogOpen ? '' : 'pointer-events-none'">
      <UTooltip
        v-if="!alertDialogOpen"
        text="Report an issue"
        :popper="{arrow: true, placement: 'left', offsetDistance: 16}">
        <SvgoAlert
          @click="toggleAlertDialog"
          class="bg-pumpkin rounded-full cursor-pointer pointer-events-auto shadow-lg hover:bg-scarlet hover:scale-110 duration-150"
          :class="alertDialogOpen ? 'bg-scarlet' : ''" />
      </UTooltip>
      <UAlert
        v-if="alertDialogOpen"
        :title="$t('feedback.title')"
        variant="solid"
        color="pumpkin"
        :description="$t('feedback.description')"
        :config="{
          wrapper: 'relative',
        }"
        :close-button="{
          icon: 'i-heroicons-x-mark-20-solid',
          color: 'white',
          variant: 'link',
          padded: false,
          onClick: () => toggleAlertDialog(),
        }"
        :actions="[
          {
            variant: 'solid',
            color: 'black',
            label: $t('feedback.actions.bug'),
            to: $t('feedback.urls.bug'),
            target: '_blank',
          },
          {
            variant: 'outline',
            color: 'white',
            label: $t('feedback.actions.feature'),
            to: $t('feedback.urls.feature'),
            target: '_blank',
          },
        ]" />
    </div>
  </teleport>
</template>

<style scoped lang="scss">
svg {
  width: 48px;
  height: 48px;
}
</style>
