<script lang="ts" setup>
import Fabric from "~/components/three/Fabric.vue";
const {typeState, tweenPositionDelta} = useSceneState();
const zPosition = computed(() => {
  return 20 * (1 - typeState.value.fabric);
});
const groupPosition = computed<[number, number, number]>(() => {
  const {centerBased, absolute} = tweenPositionDelta.value;
  return [centerBased * -5, centerBased * -5 + 2.5, zPosition.value];
});
</script>

<template>
  <Suspense>
    <TresGroup v-if="typeState.fabric" :position="groupPosition">
      <Fabric :position="[0, 0, 8]" :scale="[0.25, 0.25, 0.25]"></Fabric>
    </TresGroup>
  </Suspense>
</template>
