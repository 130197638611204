<script lang="ts" setup>
import {type PerspectiveCamera} from "three";
import BlobGroup from "~/components/three/grouped/BlobGroup.vue";
import Rendering from "~/components/three/Rendering.vue";
import ParametricGroup from "~/components/three/grouped/ParametricGroup.vue";
import {usePreferredReducedMotion} from "@vueuse/core";
import {usePrefersContrast} from "~/composables/usePrefersContrast";
import {easeValue} from "~/utils/easeValue";
import FabricGroup from "~/components/three/grouped/FabricGroup.vue";

const canvas = shallowRef();
const camera = shallowRef<PerspectiveCamera>();

const {tweenLight} = useSceneState();

const scrollControlsConfig = computed(() => {
  const preference = usePreferredReducedMotion();
  const reduced = preference.value === "reduce";
  return {
    distance: reduced ? 50 : 30,
    smoothScroll: reduced ? 1 : 0.1,
  };
});

const {prefersContrast} = usePrefersContrast();
const planeColor = computed(() => {
  if (prefersContrast.value) {
    return "#999";
  }
  return "#d9d9d9";
});

const progress = ref(0);
const lightDelta = computed(() => {
  return easeValue(progress.value, 0.0, 0.3, 2.0, 1.0);
});
</script>

<template>
  <TresCanvas ref="canvas" :resize="true" window-size>
    <Rendering></Rendering>
    <TresPerspectiveCamera
      ref="camera"
      :far="20"
      :frustum-culled="true"
      :near="0.01"
      :position="[0, 0, 15]">
      <Plane :args="[40, 40]" :position="[0, 0, -15]" :rotation="[0, 0, 0]">
        <TresMeshStandardMaterial
          :color="planeColor"></TresMeshStandardMaterial>
      </Plane>
      <TresPointLight
        name="backlight"
        :color="tweenLight"
        :distance="50"
        :intensity="50"
        :position="[0, 7.5, -17.5]">
      </TresPointLight>
      <TresPointLight
        :color="tweenLight"
        :distance="30"
        :intensity="15 * lightDelta"
        :position="[10, -2.5, -5]">
      </TresPointLight>
      <TresPointLight
        :color="AccentColors.teal.DEFAULT"
        :distance="30"
        :intensity="15 * lightDelta"
        :position="[-7.5, 2.5, -5]">
      </TresPointLight>
    </TresPerspectiveCamera>
    <ScrollControls
      v-model="progress"
      :smooth-scroll="scrollControlsConfig.smoothScroll"
      :distance="scrollControlsConfig.distance"
      html-scroll>
    </ScrollControls>
    <TresPointLight
      :color="AccentColors.teal[200]"
      :distance="22.5"
      :intensity="100"
      :position="[7, 7, 15]">
    </TresPointLight>
    <ParametricGroup></ParametricGroup>
    <BlobGroup></BlobGroup>
    <FabricGroup></FabricGroup>
  </TresCanvas>
</template>

<style lang="scss" scoped>
canvas {
  background-color: #000;
  z-index: -1;
}
</style>
