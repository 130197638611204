import {ref} from "vue";

export const usePrefersContrast = () => {
  const prefersContrast = ref(false);
  if (import.meta.client) {
    function updatePrefersContrast() {
      prefersContrast.value =
        window.matchMedia("(prefers-contrast: more)").matches ||
        document.documentElement.getAttribute("data-prefers-contrast") ===
          "true";
    }

    // Initial check
    updatePrefersContrast();

    // React to system contrast changes
    const contrastMediaQuery = window.matchMedia("(prefers-contrast: more)");
    contrastMediaQuery.addEventListener("change", updatePrefersContrast);

    // React to attribute changes (if modified externally)
    const observer = new MutationObserver(updatePrefersContrast);
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["data-prefers-contrast"],
    });
  }
  return {prefersContrast};
};
