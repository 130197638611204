/**
 * This function smoothly transitions a value between two defined points.
 *
 * - `startProgress` (e.g., 0.1) and `endProgress` (e.g., 0.2) define the range in which the transition occurs.
 * - `startDelta` and `endDelta` define the values at those points (e.g., 0.5 → 1.0).
 * - A smoothstep easing function is used to create a gradual transition.
 *
 * Example usage:
 * - When progress is 0.1, the output should be 0.5.
 * - When progress is 0.2, the output should be 1.0.
 * - Between these points, the value transitions smoothly.
 */
export function easeValue(
  progress: number,
  startProgress: number,
  endProgress: number,
  startDelta: number,
  endDelta: number,
) {
  // Normalize progress within the given range
  const t = Math.min(
    1,
    Math.max(0, (progress - startProgress) / (endProgress - startProgress)),
  );

  // Apply smoothstep easing function for a natural fade-in effect
  const easedT = t * t * (3 - 2 * t);

  // Interpolate between startDelta and endDelta
  return startDelta + (endDelta - startDelta) * easedT;
}
