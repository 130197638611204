<script lang="ts" setup>
import type {Page, SeoMeta} from "#magnolia-layer/types/magnolia-types";
import {joinURL} from "ufo";
import type {Ref} from "vue";
import type {NavigationRoot} from "#magnolia-layer/types/api";
import NavigationItem from "~/components/NavigationItem.vue";
import {SvgoContrastIcon} from "#components";
import LanguageSelector from "~/components/LanguageSelector.vue";
import {useWindowSize} from "@vueuse/core";

const route = useRoute();
const runtimeConfig = useRuntimeConfig();
const sitePath = useMagnoliaContext();
const siteRootNode = sitePath.nodePath?.split("/").filter((v) => v)[0];
const apiPages = runtimeConfig.public.mgnl.apiPages;
const {data: root} = await useMagnoliaFetch<
  Page & {
    title: string;
  } & SeoMeta
>(joinURL(apiPages, siteRootNode ?? ""));

const mainNavigationId = root.value?.primaryNavigation;
const navigationButton = root.value?.button;

const primaryNav: Ref<NavigationRoot | null> =
  await resolveNavigationReference(mainNavigationId);

const computedContext = computed(() => useMagnoliaContext());
watch(computedContext, async () => {
  const n = await resolveNavigationReference(mainNavigationId);
  primaryNav.value = n.value;
});

const primaryNavigationItems = computed(() => {
  return resolveNavigation(primaryNav.value) ?? [];
});

const overlayActive: Ref<boolean> = ref(false);

onBeforeMount(() => {
  toggleHead();
});

const toggleContrast = () => {
  const localMode = localStorage.getItem("prefers-contrast");
  if (localMode) {
    localStorage.removeItem("prefers-contrast");
  } else {
    localStorage.setItem("prefers-contrast", "more");
  }

  toggleHead();
};

const toggleHead = () => {
  const item = localStorage.getItem("prefers-contrast");

  if (item) {
    useHead({
      htmlAttrs: {
        "data-prefers-contrast": "true",
      },
    });
  } else {
    useHead({
      htmlAttrs: {
        "data-prefers-contrast": "false",
      },
    });
  }
};

watch(overlayActive, () => {
  const body: HTMLBodyElement | null = document.querySelector("body");
  if (!body) return;
  overlayActive.value
    ? body.classList.add("overflow-hidden")
    : body.classList.remove("overflow-hidden");
});

watch(
  route,
  () => {
    overlayActive.value = false;
  },
  {
    deep: true,
  },
);
const menuToggle: Ref<HTMLButtonElement | undefined> = ref();
const overlay: Ref<HTMLDivElement | undefined> = ref();

const toggleMobileNavigation = () => {
  overlayActive.value = !overlayActive.value;
  if (!menuToggle.value) return;
  menuToggle.value.ariaExpanded = overlayActive.value ? "true" : "false";
  if (!overlay.value) return;
  overlay.value.ariaHidden = overlayActive.value ? "false" : "true";
};

const {width} = useWindowSize();

const hideOnSR = computed(() => {
  return !overlayActive.value && width.value <= 1024;
});

onMounted(() => {
  if (overlay.value && width.value <= 1024) overlay.value.ariaHidden = "true";
});
</script>

<template>
  <div
    class="fade-up-out text-white flex justify-between text-base z-10 fixed top-0 left-0 right-0 pb-16 pointer-events-none">
    <div class="logo z-10 pointer-events-auto" aria-label="Logo">
      <NuxtLinkLocale to="/" :aria-label="$t('aria.labels.navigation.logo')">
        <SvgoTRIMMLogo />
      </NuxtLinkLocale>
    </div>
    <button
      ref="menuToggle"
      aria-controls="main-navigation"
      :aria-label="$t('aria.labels.navigation.toggle')"
      @click="toggleMobileNavigation"
      class="actions flex items-center lg:hidden z-10 mr-4 pointer-events-auto">
      <SvgoBurgerIcon
        v-if="!overlayActive"
        class="border-white border-[1px] border-solid rounded-full p-1 hover:cursor-pointer" />
      <SvgoCrossIcon
        v-if="overlayActive"
        class="border-white border-[1px] border-solid rounded-full p-1 hover:cursor-pointer" />
    </button>
    <div
      ref="overlay"
      class="transition-all flex fixed inset-0 bg-nightblue bg-opacity-75 contrast-custom:bg-opacity-100 backdrop-blur-sm lg:backdrop-blur-0 lg:contents lg:pointer-events-auto p-16"
      id="main-navigation"
      :inert="hideOnSR"
      :class="{
        'opacity-0 pointer-events-none': !overlayActive,
        'opacity-100 pointer-events-auto': overlayActive,
      }">
      <div
        class="flex flex-col lg:flex-row lg:items-end gap-4 lg:mr-4 w-full lg:w-auto text-center justify-center lg:justify-start">
        <nav id="main-navigation" class="lg:mr-4" role="navigation">
          <ul
            class="flex flex-col lg:flex-row gap-4 font-bold pb-1 text-2xl lg:text-sm"
            :aria-label="$t('aria.labels.navigation.list')">
            <template v-for="item in primaryNavigationItems">
              <li>
                <NavigationItem :item :hover-underline="true"></NavigationItem>
              </li>
            </template>
          </ul>
        </nav>
        <TrimmButton
          v-if="navigationButton"
          :button="navigationButton"
          class="mb-[10dvh] lg:mb-0" />
        <LanguageSelector />
        <div>
          <button
            class="trimm-button toggle-only contrast-custom:bg-white contrast-custom:text-black"
            :aria-label="$t('aria.labels.navigation.contrast')"
            @click="toggleContrast">
            <span class="pr-4">High contrast</span>
            <SvgoContrastIcon />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
:deep(.trimm-button) {
  @apply text-base lg:text-sm;

  &:hover {
    svg {
      @apply motion-safe:translate-x-0;
    }
  }
}

.logo {
  svg {
    height: 4rem;
    width: auto;
    margin-bottom: 0;
  }
}

.actions {
  svg {
    height: 2rem;
    width: auto;
  }
}

.fade-up-out {
  background-image: linear-gradient(
    hsl(0, 0%, 0%) 0%,
    hsla(0, 0%, 0%, 0.738) 19%,
    hsla(0, 0%, 0%, 0.541) 34%,
    hsla(0, 0%, 0%, 0.382) 47%,
    hsla(0, 0%, 0%, 0.278) 56.5%,
    hsla(0, 0%, 0%, 0.194) 65%,
    hsla(0, 0%, 0%, 0.126) 73%,
    hsla(0, 0%, 0%, 0.075) 80.2%,
    hsla(0, 0%, 0%, 0.042) 86.1%,
    hsla(0, 0%, 0%, 0.021) 91%,
    hsla(0, 0%, 0%, 0.008) 95.2%,
    hsla(0, 0%, 0%, 0.002) 98.2%,
    hsla(0, 0%, 0%, 0) 100%
  );

  &:after {
    z-index: -1;
    backdrop-filter: blur(4px);
    mask: linear-gradient(to top, transparent 10%, black 90%);
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
