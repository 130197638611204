<script lang="ts" setup>
import Blob from "@/components/three/Blob.vue";

const {typeState, tweenPositionDelta} = useSceneState();

const degToRad = (deg: number) => {
  return (deg * Math.PI) / 180.0;
};

const zPosition = computed(() => {
  return 20 * (1 - typeState.value.blob);
});

const groupPosition = computed<[number, number, number]>(() => {
  const {centerBased, absolute} = tweenPositionDelta.value;
  return [centerBased * 5, absolute * -10 + 5, zPosition.value];
});
</script>

<template>
  <Suspense>
    <TresGroup :position="[0, 0, 5]">
      <TresGroup v-if="typeState.blob">
        <Blob :position="groupPosition" :scale="[3, 2, 1]"></Blob>
      </TresGroup>

      <TresGroup :position="[-10, -10, 0]">
        <Blob :scale="[2, 2, 0.5]"></Blob>
      </TresGroup>

      <TresGroup :position="[10, -20, 0]">
        <Blob :scale="[2, 2, 0.5]"></Blob>
      </TresGroup>

      <TresGroup :position="[-10, -30, 0]">
        <Blob :scale="[2, 2, 0.5]" :rotate="[degToRad(180), 0, 0]"></Blob>
      </TresGroup>
    </TresGroup>
  </Suspense>
</template>
