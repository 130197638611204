<script lang="ts" setup>
const {locales, locale: currentLocale} = useI18n();
import type {LocaleObject} from "@nuxtjs/i18n/dist/module";

const switchLocalePath = useSwitchLocalePath();

const availableLocales = computed(() => {
  return (locales.value as LocaleObject[]).filter((locale) => {
    return useMagnoliaLanguages().includes(locale.code);
  });
});
</script>

<template>
  <div class="flex gap-2 justify-center">
    <div v-for="locale in availableLocales">
      <NuxtLink
        :href="switchLocalePath(locale.code)"
        :aria-label="
          $t(
            locale.code == 'en'
              ? 'aria.labels.languageSelector.en'
              : 'aria.labels.languageSelector.nl',
          )
        "
        :hrefLang="locale.language"
        class="trimm-button uppercase"
        :class="{
          'trimm-button--active': locale.code === currentLocale,
        }"
        rel="alternate">
        {{ locale.code }}
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
